import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import {FaGithub, FaLink} from 'react-icons/fa'

import * as Styled from './styles';

const RoundButton = ({Icon, href}) => {
  return (
  <div onClick={(e) => e.stopPropagation()} className="pl-1">
    <a className="flex w-8 h-8 text-indigo-900 rounded-full items-center justify-center hover:text-indigo-600" href={href} target="_blank" rel="noreferrer" >
      <Icon className="w-6 h-6" />
    </a>
  </div>
  )
}


const Posts = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "projects section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "projects" }, published: { eq: true } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              description
              date(formatString: "MMM DD, YYYY")
              tags
              github
              website
              cover {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const posts = allMarkdownRemark.edges;

  return (
    <Container section>
      <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
      <Styled.Posts>
        {posts.map((item) => {
          const {
            id,
            fields: { slug },
            frontmatter: { title, cover, description, date, tags, github, website }
          } = item.node;

          return (
            <Styled.Post key={id}>
              <Link to={slug}>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                  <Styled.Card>
                    <Styled.Image>
                      <Img fluid={cover.childImageSharp.fluid} alt={title} />
                    </Styled.Image>
                    <Styled.Content>
                      <div className="flex justify-between">
                        <div className="flex flex-col">
                          <Styled.Date>{date}</Styled.Date>
                          <Styled.Title>{title}</Styled.Title>
                        </div>
                        <div className="flex flex-row-reverse z-10 w-full">
                          {github && <RoundButton Icon={FaGithub} href={github} />}
                          {website && <RoundButton Icon={FaLink} href={website} />}
                        </div>
                      </div>
                      <div className="h-20">
                        <Styled.Description>{description}</Styled.Description>
                      </div>
                    </Styled.Content>
                    <Styled.Tags>
                      {tags.map((item) => (
                        <Styled.Tag key={item}>{item}</Styled.Tag>
                      ))}
                    </Styled.Tags>
                  </Styled.Card>
                </motion.div>
              </Link>
            </Styled.Post>
          );
        })}
      </Styled.Posts>
    </Container>
  );
};

export default Posts;
